<template>
  <div class="admin-tools">
    <div
      v-show="collapsed"
      class="launcher"
      :class="{ 'ghost-mode': ghostMode }"
      @mouseenter="collapsed = false"
    >
      Show Admin Tools
      <fa-icon v-if="ghostMode" icon="ghost" size="lg" class="ghost-mode-icon" />
    </div>
    <div
      v-show="!collapsed"
      class="toolbox"
      :style="{ opacity: toolboxOpacity }"
      @mouseover="keepOpen"
      @mouseleave="fadeOut"
    >
      <div class="ghost-mode">
        <b-form-checkbox v-model="ghostModeValue" switch>
          <span>
            Ghost Mode
            <fa-icon
              v-b-popover.hover.bottom="ghostModeHelpText"
              class="question-circle help-text"
              icon="question-circle"
            />
          </span>
        </b-form-checkbox>
      </div>

      <div class="nav-button-container">
        <b-button
          :variant="firstSlide ? 'default' : 'primary'"
          class="nav-button"
          :disabled="firstSlide || disableToggles"
          aria-label="previous slide"
          @click="goToPreviousSlide"
        >
          <span>Prev Slide</span>
        </b-button>
        <b-button
          :variant="lastSlide ? 'default' : 'primary'"
          class="nav-button"
          :disabled="lastSlide || disableToggles"
          aria-label="next slide"
          @click="goToNextSlide"
        >
          <span>Next Slide</span>
        </b-button>
      </div>

      <div class="category-container">
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Name</th>
              <th>
                Completed
                <fa-icon
                  v-b-popover.hover.bottom="completedHelpText"
                  class="question-circle help-text"
                  icon="question-circle"
                />
              </th>
              <th>
                Last Visited
                <fa-icon
                  v-b-popover.hover.bottom="lastVisitedHelpText"
                  class="question-circle help-text"
                  icon="question-circle"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Stage</th>
              <td>
                <span>{{ stage }}</span>
              </td>
              <td>
                <b-form-checkbox
                  v-model="stageCompleted"
                  switch
                  :disabled="disableToggles"
                  @change.native="userToggleClick('stageCompleted', $event.target.checked)"
                />
              </td>
            </tr>
            <tr>
              <th>Step</th>
              <td>
                <span>{{ step }}</span>
              </td>
              <td>
                <b-form-checkbox
                  v-model="stepCompleted"
                  switch
                  :disabled="disableToggles"
                  @change.native="userToggleClick('stepCompleted', $event.target.checked)"
                />
              </td>
            </tr>
            <tr>
              <th>Slide</th>
              <td>
                <span>{{ slide }}</span>
              </td>
              <td>
                <b-form-checkbox
                  v-model="slideCompleted"
                  switch
                  :disabled="disableToggles"
                  @change.native="userToggleClick('slideCompleted', $event.target.checked)"
                />
              </td>
              <td>
                <b-form-checkbox
                  v-model="slideLastVisited"
                  switch
                  :disabled="disableToggles"
                  @change.native="userToggleClick('slideLastVisited', $event.target.checked)"
                />
              </td>
            </tr>
            <template v-if="shouldDisplayHostingTools">
              <tr class="mt-5">
                <td colspan="4"> <div class="table-divider my-2" /> </td>
              </tr>
              <tr class="hosting-request">
                <th>{{ hostingRequestType }} request</th>
                <td colspan="2" />
              </tr>
              <tr class="hosting-request">
                <td>
                  <div>
                    <b-button v-b-modal.reset-hosting-request-confirmation class="slim-buttons mb-2" :disabled="loading" variant="danger">
                      <b-overlay :show="loading" rounded="sm" :opacity="0" variant="black">
                        Reset {{ hostingRequestType }} Request
                      </b-overlay>
                    </b-button>
                  </div>
                </td>
              </tr>
              <template v-if="canViewHostingRequest">
                <tr v-if="hostingCallRequest">
                  <td class="d-inline-flex">
                    <div class="pt-1 pr-2">Call Requested</div>
                    <b-checkbox v-model="hostingCallRequest" :disabled="true" />
                  </td>
                </tr>
                <tr class="hosting-request--labels">
                  <td class="" colspan="4">Domain name</td>
                </tr>
                <tr class="hosting-request">
                  <td colspan="3">
                    <b-form-input
                      v-model="domainName"
                      class="domain-input"
                      type="text"
                      :state="domainValid"
                    />
                    <small v-if="!domainValid" class="error-text">Valid domain name required</small>
                  </td>
                  <td>
                    <div class="right-align-button-container">
                      <b-button
                        class="submit-button"
                        :disabled="loading || !domainValid"
                        variant="primary"
                        aria-label="Complete Request button"
                        @click="submitCompleteRequest"
                      >
                        <b-overlay :show="loading" rounded="sm" :opacity="0" variant="black">
                          Complete {{ hostingRequestType }}
                        </b-overlay>
                      </b-button>
                    </div>
                  </td>
                </tr>
                <tr v-if="hostingRequestType === 'Hosting Transfer'" class="hosting-request">
                  <td>
                    <div>
                      <b-button
                        class="slim-buttons"
                        :disabled="loading"
                        variant="primary"
                        :aria-label="showRequestInfo ? 'Hide Credentials' : 'Show Credentials' + ' button'"
                        @click="toggleShowTransferRequest"
                      >
                        <b-overlay :show="loading" rounded="sm" :opacity="0" variant="black">
                          {{ showRequestInfo ? "Hide Credentials" : "Show Credentials" }}
                        </b-overlay>
                      </b-button>
                    </div>
                  </td>
                </tr>
                <tr v-if="showRequestInfo" class="hosting-request--labels">
                  <td>Provider</td><td>Username</td><td>Password</td>
                </tr>
                <tr v-if="showRequestInfo" class="hosting-transfer--border-cells">
                  <td>{{ hostingTransferInfo?.provider }}</td>
                  <td> {{ hostingTransferInfo?.username }}</td>
                  <td>{{ hostingTransferInfo?.password }}</td>
                </tr>
              </template>
              <b-modal
                id="reset-hosting-request-confirmation"
                title="Confirm"
                size="sm"
                centered
                cancel-variant="outline-danger"
                @ok="resetHostingRequest"
              >
                Are you sure you want to reset the {{ hostingRequestType }} request?
              </b-modal>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'StagelineAdminTools',
  mixins: [
    makeToastMixin,
  ],
  data() {
    return {
      collapsed: true,
      collapseTimer: null,
      toolboxOpacity: 1,
      disableToggles: false,
      loading: false,
      showRequestInfo: false,
      domainName: null,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'ghostMode',
      'currentPeriodSlides',
      'currentSlidePeriodIndex',
      'currentStage',
      'currentStep',
      'currentSlide',
      'company',
    ]),
    ...mapGetters('domains', [
      "hostingTransferInfo",
      "standaloneHostingInfo",
    ]),
    ...mapGetters('vouchers', [
      "voucherByProductCategory",
    ]),
    ghostModeValue: {
      get() {
        return this.ghostMode
      },
      set(value) {
        this.setGhostMode(value)
      },
    },
    stageCompleted: {
      get() {
        return !!this.currentStage?.completed_at
      },
      set() {
        // handling setter logic using @change.native => userToggleClick instead
        // this prevents switching slides triggering the api call without a user click
        // but we still need the computed getter to update the toggle value when switching slides
      },
    },
    stepCompleted: {
      get() {
        return !!this.currentStep?.completed_at
      },
      set() {
        // handling setter logic using @change.native => userToggleClick instead
        // this prevents switching slides triggering the api call without a user click
        // but we still need the computed getter to update the toggle value when switching slides
      },
    },
    slideCompleted: {
      get() {
        return !!this.currentSlide?.completed_at
      },
      set() {
        // handling setter logic using @change.native => userToggleClick instead
        // this prevents switching slides triggering the api call without a user click
        // but we still need the computed getter to update the toggle value when switching slides
      },
    },
    slideLastVisited: {
      get() {
        return this.lastVisitedSlide === this.currentSlide?.id
      },
      set() {
        // handling setter logic using @change.native => userToggleClick instead
        // this prevents switching slides triggering the api call without a user click
        // but we still need the computed getter to update the toggle value when switching slides
      },
    },
    hostingCallRequest: {
      get() {
        return this.hostingTransferInfo?.requestCall || this.standaloneHostingInfo?.requestCall
      },
      set() {},
    },
    lastVisitedSlide() {
      const sortedCurrentPeriodSlides =
        cloneDeep(this.currentPeriodSlides)
          .filter(slide => slide.last_visited)
          .sort((a, b) => (new Date(b.last_visited)) - (new Date(a.last_visited)))
      return sortedCurrentPeriodSlides[0]?.id || null
    },
    ghostModeHelpText() {
      return 'Allows an admin user to navigate through Stageline like a client without completing stages, steps, or slides.'
    },
    completedHelpText() {
      return 'Will mark complete or incomplete a Stage, Step, or Slide for the client.'
    },
    lastVisitedHelpText() {
      return `Sets the current slide as the client's "last visited" so next time client logs into Stageline they will begin at that slide.`
    },
    firstSlide() {
      return this.currentSlidePeriodIndex === 0 || this.currentPeriodSlides.length === 0
    },
    lastSlide() {
      return this.currentSlidePeriodIndex === this.currentPeriodSlides.length - 1
    },
    stage() {
      return this.currentStage?.name
    },
    step() {
      return this.currentStep?.name.replace(`${this.stage}__`, '')
    },
    slide() {
      return this.currentSlide?.name.replace(`${this.stage}__${this.step}__`, '')
    },
    canViewHostingRequest() {
      return this.hostingTransferInfo?.admin || this.standaloneHostingInfo?.admin
    },
    slideIsOnlinePresence() {
      return ['online_presence', 'configure_domain', 'configure_hosting'].includes(this.stage)
    },
    domainValid(){
      const pattern = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.(?!-)([A-Za-z0-9-]{1,63}(?<!-)\.)*[A-Za-z]{2,}$/
      return !!this.domainName && pattern.test(this.domainName)
    },
    companyId(){
      return this.$route.params.companyId
    },
    hostingRequestType() {
      if (this.standaloneHostingInfo) {
        return 'Standalone Hosting'
      } else if (this.hostingTransferInfo) {
        return 'Hosting Transfer'
      }
      return null
    },
    shouldDisplayHostingTools() {
      return this.slideIsOnlinePresence && !!this.hostingRequestType
    },
    justEmailHosting() {
      return this.voucherByProductCategory('business-email') &&
        !this.voucherByProductCategory('business-website-hosting')
    },
  },
  watch: {
    hostingRequestType: {
      handler: 'autofillDomainInput',
      immediate: true,
    },
  },
  methods: {
    ...mapActions('stageline', [
      'setGhostMode',
      'goToNextSlide',
      'goToPreviousSlide',
      'adminManuallySetComplete',
      'adminManuallySetLastVisited',
      'goToSlide',
    ]),
    ...mapActions('domains', [
      'fetchHostingTransferRequest',
      'completeHostingTransferRequest',
      'deleteHostingTransferRequest',
      'fetchStandaloneHostingRequest',
      'completeStandaloneHostingRequest',
      'deleteStandaloneHostingRequest',
    ]),
    async resetHostingRequest(){
      this.loading = true
      const hostingRequestType = this.hostingRequestType
      try {
        let result
        if (hostingRequestType === 'Hosting Transfer') {
          result = await this.deleteHostingTransferRequest(
            {
              companyId: this.companyId,
            })
        } else if (hostingRequestType === 'Standalone Hosting') {
          result = await this.deleteStandaloneHostingRequest(
            {
              companyId: this.companyId,
            })
        } else {
          this.errorToast("Error", "Invalid hosting request type.")
          return
        }
        if(!result.data.success){
          this.errorToast("API Error", `There was an error resetting the ${hostingRequestType} request.`)
          return
        }
        this.domainName = null
        if (hostingRequestType === 'Standalone Hosting') {
          this.goToSlide(this.currentStage?.steps[0]?.slides[0]?.name)
          await this.adminManuallySetComplete({ category: 'step', id: this.currentStep.id, value: false })
          await this.adminManuallySetComplete({ category: 'slide', id: this.currentSlide.id, value: false })
        }
        this.successToast('Success', `${hostingRequestType} request has been reset!`)
      } catch {
        this.errorToast("API Error", `There was an error resetting the ${hostingRequestType} request.`)
      } finally {
        this.loading = false
      }
    },
    async submitCompleteRequest() {
      this.loading = true
      const hostingRequestType = this.hostingRequestType
      try {
        let result
        if (hostingRequestType === 'Hosting Transfer') {
          result = await this.completeHostingTransferRequest(
            {
              companyId: this.companyId,
              domainName: this.domainName,
            })
        } else if (hostingRequestType === 'Standalone Hosting') {
          result = await this.completeStandaloneHostingRequest(
            {
              companyId: this.companyId,
              domainName: this.domainName,
            })
        } else {
          this.errorToast("Error", "Invalid hosting request type.")
          return
        }
        if(!result.data.success){
          this.errorToast("API Error", `There was an error completing the ${hostingRequestType} request.`)
          return
        }
        this.domainName = null
        this.successToast('Success', `${hostingRequestType} request completed!`)
        if(hostingRequestType === 'Hosting Transfer' ||
          (hostingRequestType === 'Standalone Hosting' && this.justEmailHosting)) {
          this.$emit('next-step')
        }
      } catch {
        this.errorToast("API Error", `There was an error completing the ${hostingRequestType} request.`)
      } finally {
        this.loading = false
      }
    },
    async toggleShowTransferRequest() {
      if (!this.showRequestInfo &&
        !this.hostingTransferInfo.username &&
        !this.hostingTransferInfo.password &&
        this.hostingTransferInfo.admin
      ) {
        this.loading = true
        try {
          const result = await this.fetchHostingTransferRequest(
            {
              companyId: this.companyId,
              showCredentials: true,
            })
          if(result.status === 400){
            this.errorToast("API Error", "There was an error getting the hosting transfer request info.")
          }
          this.showRequestInfo = true
        } catch {
          this.errorToast("API Error", "There was an error getting the hosting transfer request info.")
        } finally {
          this.loading = false
        }
      } else {
        this.showRequestInfo = !this.showRequestInfo
      }
    },
    keepOpen() {
      clearInterval(this.collapseTimer)
      this.collapseTimer = null
      this.toolboxOpacity = 1
    },
    fadeOut() {
      if (this.collapseTimer) return
      this.collapseTimer = setInterval(() => {
        this.toolboxOpacity -= 0.05
        if (this.toolboxOpacity <= 0) {
          this.collapsed = true
          this.toolboxOpacity = 1
          clearInterval(this.collapseTimer)
          this.showRequestInfo = false
          this.domainName = null
        }
      },100)
    },
    async userToggleClick(caller, value) {
      this.disableToggles = true
      switch(caller) {
        case 'stageCompleted':
          await this.adminManuallySetComplete({ category: 'stage', id: this.currentStage.id, value })
          break
        case 'stepCompleted':
          await this.adminManuallySetComplete({ category: 'step', id: this.currentStep.id, value })
          break
        case 'slideCompleted':
          await this.adminManuallySetComplete({ category: 'slide', id: this.currentSlide.id, value })
          break
        case 'slideLastVisited':
          await this.adminManuallySetLastVisited({ value })
          break
        default: break
      }
      this.disableToggles = false
    },
    autofillDomainInput() {
      if (this.standaloneHostingInfo) {
        this.domainName = this.standaloneHostingInfo.domainName || null
      }
    },
  },
}
</script>

<style lang="scss">
.popover {
  z-index: 10001;
}
</style>
<style scoped lang="scss">

.admin-tools {
  font-size: .7em;
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 10000;

  table {
    border-collapse: separate;
  }

  .table-divider{
    border-top: 1px solid lightgray;
  }

  .hosting-request {
    td{
      vertical-align: top;
    }

    &--labels {
      td {
        font-weight: bold;
        font-size: smaller;
        padding-top: 0;
      }
    }

    &--border-cells {
      td {
        border: 1px solid black;
        border-spacing: 2px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .error-text {
      color: red;
    }

    .domain-input {
      min-height: 34px !important;
      max-height: 34px;
    }

    .slim-buttons {
      height: 20px;
      padding: 0 0.3125em;
      font-size: x-small;
      align-self: start;
    }

    .right-align-button-container {
      text-align: end;
      margin-left: 5px;

      .submit-button {
        height: 30px;
        padding: 0 0.3125em;
        font-size: small;
        margin-bottom: 18px;
      }
    }
  }

  .question-circle {
    color: $ct-ui-primary !important;
  }

  .launcher {
    opacity: 0.5;
    border: black solid 2px;
    padding: 0.3125em;
    border-radius: 4px;
    &.ghost-mode {
      border: $ct-ui-primary solid 2px !important;
      opacity: 1 !important;
    }
  }

  .ghost-mode-icon {
    color: $ct-ui-primary;
    padding-left: 0.3125em;
  }

  .toolbox {
    background-color: white;
    border: black solid 2px;
    padding: 2px 10px;

    .ghost-mode {
      width: 100%;
      text-align: right;

      span {
        display: block;
        margin-top: 3px;
        font-weight: bold;
      }
    }

    .nav-button-container {
      width: 100%;
      text-align: right;
      margin: 5px 0;

      .nav-button {
        height: 1.5rem;
        padding: 0.15rem 0.5rem;
        width: 90px;
        margin-left: 10px;
        background: $ct-ui-primary;

        span {
          vertical-align: text-top;
          font-size: 0.7em;
        }
      }
    }

    .category-container {
      th:not(:last-of-type),
      td span {
        padding-right: 25px;
      }

      tbody .custom-switch {
        padding-left: 55px;
      }
    }
  }
}
</style>
