var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "admin-tools" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.collapsed,
            expression: "collapsed",
          },
        ],
        staticClass: "launcher",
        class: { "ghost-mode": _vm.ghostMode },
        on: {
          mouseenter: function ($event) {
            _vm.collapsed = false
          },
        },
      },
      [
        _vm._v("\n    Show Admin Tools\n    "),
        _vm.ghostMode
          ? _c("fa-icon", {
              staticClass: "ghost-mode-icon",
              attrs: { icon: "ghost", size: "lg" },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.collapsed,
            expression: "!collapsed",
          },
        ],
        staticClass: "toolbox",
        style: { opacity: _vm.toolboxOpacity },
        on: { mouseover: _vm.keepOpen, mouseleave: _vm.fadeOut },
      },
      [
        _c(
          "div",
          { staticClass: "ghost-mode" },
          [
            _c(
              "b-form-checkbox",
              {
                attrs: { switch: "" },
                model: {
                  value: _vm.ghostModeValue,
                  callback: function ($$v) {
                    _vm.ghostModeValue = $$v
                  },
                  expression: "ghostModeValue",
                },
              },
              [
                _c(
                  "span",
                  [
                    _vm._v("\n          Ghost Mode\n          "),
                    _c("fa-icon", {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.bottom",
                          value: _vm.ghostModeHelpText,
                          expression: "ghostModeHelpText",
                          modifiers: { hover: true, bottom: true },
                        },
                      ],
                      staticClass: "question-circle help-text",
                      attrs: { icon: "question-circle" },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "nav-button-container" },
          [
            _c(
              "b-button",
              {
                staticClass: "nav-button",
                attrs: {
                  variant: _vm.firstSlide ? "default" : "primary",
                  disabled: _vm.firstSlide || _vm.disableToggles,
                  "aria-label": "previous slide",
                },
                on: { click: _vm.goToPreviousSlide },
              },
              [_c("span", [_vm._v("Prev Slide")])]
            ),
            _c(
              "b-button",
              {
                staticClass: "nav-button",
                attrs: {
                  variant: _vm.lastSlide ? "default" : "primary",
                  disabled: _vm.lastSlide || _vm.disableToggles,
                  "aria-label": "next slide",
                },
                on: { click: _vm.goToNextSlide },
              },
              [_c("span", [_vm._v("Next Slide")])]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "category-container" }, [
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Category")]),
                _c("th", [_vm._v("Name")]),
                _c(
                  "th",
                  [
                    _vm._v("\n              Completed\n              "),
                    _c("fa-icon", {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.bottom",
                          value: _vm.completedHelpText,
                          expression: "completedHelpText",
                          modifiers: { hover: true, bottom: true },
                        },
                      ],
                      staticClass: "question-circle help-text",
                      attrs: { icon: "question-circle" },
                    }),
                  ],
                  1
                ),
                _c(
                  "th",
                  [
                    _vm._v("\n              Last Visited\n              "),
                    _c("fa-icon", {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.bottom",
                          value: _vm.lastVisitedHelpText,
                          expression: "lastVisitedHelpText",
                          modifiers: { hover: true, bottom: true },
                        },
                      ],
                      staticClass: "question-circle help-text",
                      attrs: { icon: "question-circle" },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "tbody",
              [
                _c("tr", [
                  _c("th", [_vm._v("Stage")]),
                  _c("td", [_c("span", [_vm._v(_vm._s(_vm.stage))])]),
                  _c(
                    "td",
                    [
                      _c("b-form-checkbox", {
                        attrs: { switch: "", disabled: _vm.disableToggles },
                        nativeOn: {
                          change: function ($event) {
                            return _vm.userToggleClick(
                              "stageCompleted",
                              $event.target.checked
                            )
                          },
                        },
                        model: {
                          value: _vm.stageCompleted,
                          callback: function ($$v) {
                            _vm.stageCompleted = $$v
                          },
                          expression: "stageCompleted",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("Step")]),
                  _c("td", [_c("span", [_vm._v(_vm._s(_vm.step))])]),
                  _c(
                    "td",
                    [
                      _c("b-form-checkbox", {
                        attrs: { switch: "", disabled: _vm.disableToggles },
                        nativeOn: {
                          change: function ($event) {
                            return _vm.userToggleClick(
                              "stepCompleted",
                              $event.target.checked
                            )
                          },
                        },
                        model: {
                          value: _vm.stepCompleted,
                          callback: function ($$v) {
                            _vm.stepCompleted = $$v
                          },
                          expression: "stepCompleted",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("Slide")]),
                  _c("td", [_c("span", [_vm._v(_vm._s(_vm.slide))])]),
                  _c(
                    "td",
                    [
                      _c("b-form-checkbox", {
                        attrs: { switch: "", disabled: _vm.disableToggles },
                        nativeOn: {
                          change: function ($event) {
                            return _vm.userToggleClick(
                              "slideCompleted",
                              $event.target.checked
                            )
                          },
                        },
                        model: {
                          value: _vm.slideCompleted,
                          callback: function ($$v) {
                            _vm.slideCompleted = $$v
                          },
                          expression: "slideCompleted",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("b-form-checkbox", {
                        attrs: { switch: "", disabled: _vm.disableToggles },
                        nativeOn: {
                          change: function ($event) {
                            return _vm.userToggleClick(
                              "slideLastVisited",
                              $event.target.checked
                            )
                          },
                        },
                        model: {
                          value: _vm.slideLastVisited,
                          callback: function ($$v) {
                            _vm.slideLastVisited = $$v
                          },
                          expression: "slideLastVisited",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm.shouldDisplayHostingTools
                  ? [
                      _vm._m(0),
                      _c("tr", { staticClass: "hosting-request" }, [
                        _c("th", [
                          _vm._v(_vm._s(_vm.hostingRequestType) + " request"),
                        ]),
                        _c("td", { attrs: { colspan: "2" } }),
                      ]),
                      _c("tr", { staticClass: "hosting-request" }, [
                        _c("td", [
                          _c(
                            "div",
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName:
                                        "v-b-modal.reset-hosting-request-confirmation",
                                      modifiers: {
                                        "reset-hosting-request-confirmation": true,
                                      },
                                    },
                                  ],
                                  staticClass: "slim-buttons mb-2",
                                  attrs: {
                                    disabled: _vm.loading,
                                    variant: "danger",
                                  },
                                },
                                [
                                  _c(
                                    "b-overlay",
                                    {
                                      attrs: {
                                        show: _vm.loading,
                                        rounded: "sm",
                                        opacity: 0,
                                        variant: "black",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Reset " +
                                          _vm._s(_vm.hostingRequestType) +
                                          " Request\n                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm.canViewHostingRequest
                        ? [
                            _vm.hostingCallRequest
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    { staticClass: "d-inline-flex" },
                                    [
                                      _c("div", { staticClass: "pt-1 pr-2" }, [
                                        _vm._v("Call Requested"),
                                      ]),
                                      _c("b-checkbox", {
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.hostingCallRequest,
                                          callback: function ($$v) {
                                            _vm.hostingCallRequest = $$v
                                          },
                                          expression: "hostingCallRequest",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._m(1),
                            _c("tr", { staticClass: "hosting-request" }, [
                              _c(
                                "td",
                                { attrs: { colspan: "3" } },
                                [
                                  _c("b-form-input", {
                                    staticClass: "domain-input",
                                    attrs: {
                                      type: "text",
                                      state: _vm.domainValid,
                                    },
                                    model: {
                                      value: _vm.domainName,
                                      callback: function ($$v) {
                                        _vm.domainName = $$v
                                      },
                                      expression: "domainName",
                                    },
                                  }),
                                  !_vm.domainValid
                                    ? _c(
                                        "small",
                                        { staticClass: "error-text" },
                                        [_vm._v("Valid domain name required")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "right-align-button-container",
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "submit-button",
                                        attrs: {
                                          disabled:
                                            _vm.loading || !_vm.domainValid,
                                          variant: "primary",
                                          "aria-label":
                                            "Complete Request button",
                                        },
                                        on: {
                                          click: _vm.submitCompleteRequest,
                                        },
                                      },
                                      [
                                        _c(
                                          "b-overlay",
                                          {
                                            attrs: {
                                              show: _vm.loading,
                                              rounded: "sm",
                                              opacity: 0,
                                              variant: "black",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Complete " +
                                                _vm._s(_vm.hostingRequestType) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _vm.hostingRequestType === "Hosting Transfer"
                              ? _c("tr", { staticClass: "hosting-request" }, [
                                  _c("td", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "slim-buttons",
                                            attrs: {
                                              disabled: _vm.loading,
                                              variant: "primary",
                                              "aria-label": _vm.showRequestInfo
                                                ? "Hide Credentials"
                                                : "Show Credentials" +
                                                  " button",
                                            },
                                            on: {
                                              click:
                                                _vm.toggleShowTransferRequest,
                                            },
                                          },
                                          [
                                            _c(
                                              "b-overlay",
                                              {
                                                attrs: {
                                                  show: _vm.loading,
                                                  rounded: "sm",
                                                  opacity: 0,
                                                  variant: "black",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.showRequestInfo
                                                        ? "Hide Credentials"
                                                        : "Show Credentials"
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.showRequestInfo
                              ? _c(
                                  "tr",
                                  { staticClass: "hosting-request--labels" },
                                  [
                                    _c("td", [_vm._v("Provider")]),
                                    _c("td", [_vm._v("Username")]),
                                    _c("td", [_vm._v("Password")]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.showRequestInfo
                              ? _c(
                                  "tr",
                                  {
                                    staticClass:
                                      "hosting-transfer--border-cells",
                                  },
                                  [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.hostingTransferInfo?.provider
                                        )
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.hostingTransferInfo?.username
                                          )
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.hostingTransferInfo?.password
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _c(
                        "b-modal",
                        {
                          attrs: {
                            id: "reset-hosting-request-confirmation",
                            title: "Confirm",
                            size: "sm",
                            centered: "",
                            "cancel-variant": "outline-danger",
                          },
                          on: { ok: _vm.resetHostingRequest },
                        },
                        [
                          _vm._v(
                            "\n              Are you sure you want to reset the " +
                              _vm._s(_vm.hostingRequestType) +
                              " request?\n            "
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "mt-5" }, [
      _c("td", { attrs: { colspan: "4" } }, [
        _c("div", { staticClass: "table-divider my-2" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "hosting-request--labels" }, [
      _c("td", { attrs: { colspan: "4" } }, [_vm._v("Domain name")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }